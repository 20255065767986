import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';


import { Seo } from 'components/contentful/seo/Seo';
import { SubPage } from 'components/news/sub-page/SubPage';
import { Billboard } from 'components/contentful/billboard/Billboard';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { WrapperBgs } from 'components/container/WrapperBgs'
import { Mesh } from 'components/bg-svgs/mesh/mesh/Mesh';
import { UIContext } from 'context/ui';
import { browserTargeting } from 'utils/browserTargeting';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FeaturedArticleCarousel } from 'components/blog/featured-article-carousel/FeaturedArticleCarousel';
import { Hero } from 'components/contentful/hero/Hero';
import { ArticlesBoard } from 'components/blog/articles-board/ArticlesBoard';
import { PressReleases } from 'components/blog/press-releases/PressReleases';
import { Community } from 'components/blog/community/Community';
import { News } from 'components/blog/news/News';
import { LatestImages } from 'components/blog/latest-images/LatestImages';
import { DashboardCTA } from 'components/blog/dashboard-cta/DashboardCTA';
import { BgLine1 } from 'components/bg-svgs/lines/news-new/bg-line-1/BgLine1';
import { BgLine7 } from 'components/bg-svgs/lines/home/bg-line-7/BgLine7';
import { BgLine11 } from 'components/bg-svgs/lines/home/bg-line-11/BgLine11';
import { BgLine2 } from 'components/bg-svgs/lines/news-new/bg-line-2/BgLine2';
import { SLine } from 'components/bg-svgs/lines/news-new/straight-line/SLine';
import { BgLine3 } from 'components/bg-svgs/lines/news-new/bg-line-3/BgLine3';
import { BgLine4 } from 'components/bg-svgs/lines/news-new/bg-line-4/BgLine4';
import Page from 'templates/page';
import { SocialBanner } from 'components/social-banner/SocialBanner';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';

gsap.registerPlugin(ScrollTrigger);

interface IProps {
  pageContext: any;
  prismic?: any;
  data: any;
  location?: Location;
}

export default ({ data, pageContext, location }: any) => {
  if (!data) return;

  const contentfulData = data.contentfulData;
  const page = data.page;
  const billboard = contentfulData.billboard;
  const articles = contentfulData.articles.sort((a:any, b:any) => {return (new Date(b.date).getTime() - new Date(a.date).getTime())});
  const categories:any[] = [];
  data.categories.edges.map((el:any) => {
    let exists = false
    categories.forEach((cat) => {if (cat.id === el.node.id) exists = true})
    if (!exists) categories.push(el.node);
  }
  );
  const subcategories:any[] = []
  data.subcategories.edges.map((el: any) =>  {
    const node = {
      name: el.node.name,
      id: el.node.id,
      parentId: el.node.parentCategory && el.node.parentCategory.id
    }
    let exists = false;
    subcategories.forEach((sub:any) => { if (sub.id === node.id) exists = true});
    if (!exists) subcategories.push(node);
  });
  const seo = page.seo;
  const [activeTab, setActiveTab] = React.useState<string>('articles');
  const { isDesktop } = useContext(UIContext);
  const browser = browserTargeting()
  const [blogs, setBlogs] = useState([]);
  const getHubspotBlogs = async () => {
      const action = `/.netlify/functions/hubspotBlogs`;
      const res = await fetch(action);
      const {results} = await res.json();
      setBlogs(results);
  }
  useEffect(() => {
    getHubspotBlogs();
  }, [])
  return (
    <>
      {
        seo &&
        <Seo
        seo={seo}
        url={location!.pathname}
        />
      }
      

      <BgGradient />
        <WrapperBgs>
          <Hero hero={{...page.hero, clearPaddingBottom: true}}/>
          {isDesktop && <BgLine1/>}
        </WrapperBgs>

        <WrapperBgs>
          <FeaturedArticleCarousel list={page.body[0]}/>
          {isDesktop && <SLine/>}
        </WrapperBgs>

        {
          blogs.length > 0 &&
          <WrapperBgs>
            <ArticlesBoard
                item={page.body[1]}
                list={
                  {
                    items: blogs
                  }}/>
              {isDesktop && <BgLine2/>}
          </WrapperBgs>
        }
        

        <WrapperBgs>
          {/* {isDesktop && <BgLine3/>} */}
          <PressReleases item={page.body[2]} />
          {/* <Community/> */}
        </WrapperBgs>
        
        <WrapperBgs>
          <SocialBanner list={page.body[5]}/>
        </WrapperBgs>

        <WrapperBgs>
          <News/>
        </WrapperBgs>

        <WrapperBgs >
          <LatestImages item={page.body[4]}/>
          {isDesktop && <BgLine4/>}
        </WrapperBgs>
        <WrapperBgs>
          <MediaTextContentful
            mediaText={page.body[6]}
            layout="column"
            style={{
              marginTop: isDesktop && 130,
              padding: isDesktop && '100px 0'
            }}
            textStyle={{maxWidth: 520}}
            titleStyle={{
              fontSize: isDesktop ? 50 : 30,
              lineHeight: isDesktop ? '64px' : '42px'
            }}
            pStyle={{
              color: '#BCBFBF'
            }}
          />
        </WrapperBgs>
      
      <WrapperBgs>
        <Mesh/>
      </WrapperBgs>
    </>
  );
};

export const query = graphql`
  { 
    contentfulData: contentfulBlogPageType {
      articles {
        id
        title
        pageSlug
        date
        body {
          ... on ContentfulBlockQuote {
            author
            text {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCta {
            href
            label
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulEmbedHtml {
            id
            html {
              html
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulImage {
            id
            image {
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
              description
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            caption
          }
          ... on ContentfulImagesCarousel {
            id
            items {
              image {
                file {
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
                description
              }
              caption
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulQuote {
            id
            author
            text {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulRichText {
            text {
              raw
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
        categories {
          id
          name
        }
        subcategories {
          name
          id
          parentCategory {
            name
          }
        }
        lightTheme
        excerpt {
          excerpt
        }
        imageDesktop {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
        imageMobile {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          description
        }
      }
      billboard {
        cta {
          href
          label
        }
        title
        text {
          raw
        }
      }
      title
      headline
      featuredArticle {
        id
      }
      featuredArticleType
    }
    categories: allContentfulArticleCategory{
      edges {
        node {
          id
          name
        }
      }
    }
    subcategories: allContentfulArticleSubcategory {
      edges {
        node {
          id
          name
          parentCategory {
            name
            id
          }
        }
      }
    }
    page: contentfulPage(name: {eq: "Newsroom"}) {
      hero {
        title
        text {
          raw
        }
      }
      seo {
        description
        title
        twitterImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        facebookImage {
          file {
            details {
              image {
                height
                width
              }
            }
            url
          }
          description
        }
        no_index
        no_follow
        name
      }
      body {
        ... on ContentfulMediaText {
          id
          videoUrl
          autoplay
          showPlayButton
          video {
            description
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          title
          text {
            raw
          }
          sectionBackground {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            description
          }
          cta {
            href
            label
          }
          imageMobile {
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        ... on ContentfulList {
          id
          title
          text {
            raw
          }
          items {
            title
            text {
              raw
            }
            cta {
              href
              label
            }
            image {
              gatsbyImageData
              description
              file {
                url
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            tag
            date
          }
        }
        ... on ContentfulListItem {
          cta {
            href
            label
          }
          text {
            raw
          }
          title
          tag
        }
      }
    }
  }
`;
