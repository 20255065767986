import React from "react";
import s from './BgLine1.scss';
import { useEffect, useRef } from "react";
import gsap from "gsap";


export const BgLine1 = () => {

    const lineRef = useRef(null);

    useEffect(() => {
        gsap.fromTo(
        lineRef.current,
        {
            drawSVG: '0%',
        },
        {
            duration: 2.5,
            delay: 1.5,
            drawSVG: '100%',
            ease: 'linear',
        }
        );
    }, [])
    return (
        <div id="hero__line" className={s.hero__line}>
            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" fill="none">

                <path
                ref={lineRef}
                xmlns="http://www.w3.org/2000/svg" opacity="0.3" d="M1 0V0C1 83.229 68.4705 150.7 151.7 150.7H545" stroke="#1B52AD" stroke-width="2"/>
            </svg>
        </div>
    )
}